import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import SliceZone from "components/SliceZone"
import { linkResolver } from "utils/linkResolver"

function Home({
  data: {
    prismic: { index, site_settings },
  },
  ...props
}) {
  if (!index) return null
  const { body, _meta, ...meta } = index
  const uri = linkResolver(_meta)
  return (
    <Layout site_settings={site_settings}>
      <SEO
        slug={uri}
        title={meta.meta_title}
        description={meta.meta_description}
        images={meta.meta_images}
      />
      <SliceZone slices={body} />
    </Layout>
  )
}

export default Home

Home.defaultProps = {}

Home.propTypes = {}

export const query = graphql`
  query Index($lang: String!, $uid: String!, $isProduction: Boolean!) {
    prismic {
      index(lang: $lang, uid: $uid) {
        _meta {
          uid
          type
          lang
        }
        meta_title
        meta_description
        meta_images {
          image
          imageSharp @include(if: $isProduction) {
            childImageSharp {
              id
              main: fixed(quality: 80, width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        body {
          ... on PRISMIC_IndexBodyTiles {
            type
            label
            fields {
              tile {
                ... on PRISMIC_Tile {
                  preheader
                  header
                  button_text
                  image
                  imageSharp @include(if: $isProduction) {
                    childImageSharp {
                      id
                      main: fixed(quality: 80, width: 1280) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                      mobile: fixed(quality: 80, width: 480) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                  summary
                  link {
                    ... on PRISMIC_Article {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                  }
                }
              }
              tile_theme
            }
          }
          ... on PRISMIC_IndexBodyModule {
            type
            label
            primary {
              module {
                ... on PRISMIC_Instructions {
                  _meta {
                    type
                  }
                  steps {
                    after_icon
                    before_icon
                    step_icon
                  }
                }
              }
            }
          }
          ... on PRISMIC_IndexBodyCountdown {
            type
            label
            primary {
              countdown {
                ... on PRISMIC_Countdown {
                  ...CountdownFragment
                }
              }
            }
          }
          ... on PRISMIC_IndexBodyScroll_cta {
            type
            primary {
              scroll_content
            }
            fields {
              brand_element_group_item {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
            }
          }
          ... on PRISMIC_IndexBodyRanking {
            type
            label
            primary {
              ranking {
                ... on PRISMIC_Ranking {
                  title
                  type
                  from
                  to
                }
              }
            }
          }
          ... on PRISMIC_IndexBodyBrand_elements {
            type
            fields {
              brand_element_group_item {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
            }
          }
          ... on PRISMIC_IndexBodyJumbotron {
            type
            primary {
              jumbotron_title
              jumbotron_content
              jumbotron_link {
                ... on PRISMIC_Page {
                  page_title
                }
              }
              jumbotron_brand_element {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
              jumbotron_brand_element_position
            }
          }
          ... on PRISMIC_IndexBodyHow_to {
            type
            primary {
              how_to_content_before
              how_to_content_after
            }
          }
          ... on PRISMIC_IndexBodyCta {
            type
            primary {
              cta_content
              cta_brand_element {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
              cta_brand_element_position
            }
          }
        }
      }
      site_settings(lang: $lang, uid: "site-settings") {
        ...Footer
      }
    }
  }
`
